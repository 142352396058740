import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import logo from "../images/logo/smartLoom.jpg";

const Contact: React.FC = () => (
  <Box
    sx={{
      maxWidth: "800px",
      margin: "auto",
      padding: "20px",
      textAlign: "center",
    }}
  >
    <img
      src={logo}
      alt="SmartLoom.JPG"
      style={{ width: "150px", marginBottom: "20px" }}
    />
    <Typography variant="h2" sx={{ marginBottom: "20px" }}>
      Contact Us
    </Typography>
    <Typography variant="body1" sx={{ marginBottom: "20px" }}>
      If you have any questions, suggestions, or feedback, feel free to contact
      us via email or phone.
    </Typography>
    <Box
      sx={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}
    >
      <Link
        href="mailto:smartloom.india@gmail.com"
        style={{ color: "#333", textDecoration: "none", marginRight: "20px" }}
      >
        <EmailIcon sx={{ fontSize: 30 }} />
        <Typography variant="body1" sx={{ marginLeft: "5px" }}>
          smartloom.india@gmail.com
        </Typography>
      </Link>
      <Link
        href="tel:+1234567890"
        style={{ color: "#333", textDecoration: "none" }}
      >
        <PhoneIcon sx={{ fontSize: 30 }} />
        <Typography variant="body1" sx={{ marginLeft: "5px" }}>
          +1 (234) 567-890
        </Typography>
      </Link>
    </Box>
    <Typography variant="body1" sx={{ marginBottom: "20px" }}>
      Connect with us:
    </Typography>
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Link
        href="https://facebook.com/yourpage"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: "#1876D1",
          textDecoration: "none",
          marginRight: "20px",
        }}
      >
        <FacebookIcon sx={{ fontSize: 40 }} />
      </Link>
      <Link
        href="https://twitter.com/yourpage"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: "#1DA1F2",
          textDecoration: "none",
          marginRight: "20px",
        }}
      >
        <TwitterIcon sx={{ fontSize: 40 }} />
      </Link>
      <Link
        href="https://instagram.com/yourpage"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: "#E1306C",
          textDecoration: "none",
          marginRight: "20px",
        }}
      >
        <InstagramIcon sx={{ fontSize: 40 }} />
      </Link>
      <Link
        href="https://linkedin.com/yourpage"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "#0A66C2", textDecoration: "none" }}
      >
        <LinkedInIcon sx={{ fontSize: 40 }} />
      </Link>
    </Box>
  </Box>
);

export default Contact;
