import React, { useState, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Menu, ChevronRight } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";

interface NavigationBarProps {
  logo: string;
  pages: { label: string; path: string }[];
}

const NavigationBar: React.FC<NavigationBarProps> = ({ logo, pages }) => {
  const location = useLocation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const getActiveTab = useCallback(() => {
    const currentPath = location.pathname;
    const currentPage = pages.findIndex((page) => page.path === currentPath);
    return currentPage !== -1 ? currentPage : 0;
  }, [location.pathname, pages]);

  const [value, setValue] = useState(getActiveTab);

  React.useEffect(() => {
    setValue(getActiveTab());
  }, [location, getActiveTab]);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Box sx={{ display: "flex", flexGrow: 1, alignItems: "center" }}>
          <Link
            to="/"
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={logo}
              alt="logo"
              style={{
                height: "40px",
                marginRight: "16px",
                borderRadius: "50%",
                border: "2px solid white",
              }}
            />
            <Typography
              variant="h6"
              component="div"
              sx={{
                fontFamily: "Roboto",
                fontSize: "2.2rem",
              }}
            >
              SMART LOOM
            </Typography>
          </Link>
        </Box>

        {/* Hamburger Icon for Mobile */}
        <IconButton
          color="inherit"
          aria-label="open mobile menu"
          edge="end"
          onClick={toggleMobileMenu}
          sx={{ display: { xs: "block", sm: "none" } }} // Show on extra-small screens and hide on medium and larger screens
        >
          <Menu />
        </IconButton>
        {/* Tabs for larger screens */}
        <Tabs
          value={value}
          textColor="inherit"
          indicatorColor="secondary"
          sx={{ display: { xs: "none", sm: "flex" } }} // Show on medium and larger screens and hide on extra-small screens
        >
          {pages.map((page, index) => (
            <Tab
              key={index}
              label={page.label}
              component={Link}
              to={page.path}
              sx={{
                transition: "background-color 0.3s ease",
                "&:hover": {
                  backgroundColor: "#e0e0e0",
                },
              }}
            />
          ))}
        </Tabs>
      </Toolbar>
      {/* Mobile Menu */}
      <Drawer
        anchor="right"
        open={mobileMenuOpen}
        onClose={toggleMobileMenu}
        sx={{ display: { xs: "block", sm: "none" } }} // Show on extra-small screens and hide on medium and larger screens
        PaperProps={{
          sx: {
            backgroundColor: "#1876D1",
            color: "white",
            width: "250px",
          },
        }}
        transitionDuration={{ enter: 500, exit: 300 }} // Customize animation duration
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "8px",
            backgroundColor: "#1876D1",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="close mobile menu"
            onClick={toggleMobileMenu}
          >
            <ChevronRight />
          </IconButton>
        </Box>
        <Collapse in={mobileMenuOpen}>
          <List
            sx={{
              width: 250,
              backgroundColor: "#1876D1",
              height: "100%",
            }}
          >
            {pages.map((page, index) => (
              <ListItemButton
                key={index}
                component={Link}
                to={page.path}
                selected={location.pathname === page.path}
                onClick={() => setMobileMenuOpen(false)} // Close menu when a link is clicked
                sx={{
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#145CA8",
                    transition: "background-color 0.3s ease",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "#145CA8",
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: "#123C7E",
                  },
                }}
              >
                <ListItemText primary={page.label} />
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      </Drawer>
    </AppBar>
  );
};

export default NavigationBar;
